<template>
	<v-sheet>
		<p>Choose at least one of the data series shown below to include in your custom query. When you're done, click NEXT.</p>
		<panel-selection
			:options="options"
			:model-value="modelValue"
			@update:modelValue="
				(v) => $emit('update:modelValue', v)
			"></panel-selection>
	</v-sheet>
</template>

<script setup>
	import {computed, defineProps} from "vue";
	import {useDashboardStore} from "@/stores/modules/dashboard";
	import PanelSelection from "@/components/data-query-wizard/PanelSelection";

	defineProps({modelValue: Array});
	const dashboard = useDashboardStore();

	const options = computed(() => dashboard.series);
</script>
