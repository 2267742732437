import {defineStore} from "pinia";

export const useSectionsStore = defineStore("sections", () => {
	// settings for the collection plugin
	const type = "collection";
	const collectionName = "sections";
	const loadAll = false;

	const defaultValues = {
		status: "published"
	}
	return {
		options: {
			type,
			collectionName,
			loadAll
		}, 
		defaultValues,
	};
});
