<template>
	<v-navigation-drawer location="right" permanent :rail="rail">
		<v-list>
			<v-list-item v-if="!rail">
				<v-expansion-panels>
					<expansion-panel-with-help
						color="primary"
						icon="mdi-gesture-tap"
						title="Suggested Views"
						help="">
						<quick-link
							v-for="(link, i) in quickLinks"
							v-bind="link"
							:key="i"></quick-link>
					</expansion-panel-with-help>
					<expansion-panel-with-help
						color="primary"
						title="Detail View"
						icon="mdi-filter"
						help="Select the level of detail/data aggregation to include">
						<edit-columns type="labels"></edit-columns>
					</expansion-panel-with-help>
					<expansion-panel-with-help
						color="primary"
						title="Columns"
						icon="mdi-view-column"
						help="Choose which data columns to include">
						<edit-columns type="series"></edit-columns>
						<aggregate-column></aggregate-column>
					</expansion-panel-with-help>
					<expansion-panel-with-help
						color="secondary"
						icon="mdi-office-building"
						title="Organisations"
						help="Choose organisations available for display">
						
						<v-chip class="mb-1"
							v-for="(org, i) in includedOrganisations"
							:key="i">
							{{organisationsStore.getLabel(org).title}}
						</v-chip>
						<organisation-settings></organisation-settings> 
					</expansion-panel-with-help>
				</v-expansion-panels>
				<query-wizard-dialog></query-wizard-dialog>
				<reset-button></reset-button>
			</v-list-item>
			<v-list-item v-if="!rail">
				<handle-selected-rows></handle-selected-rows>
			</v-list-item>
		</v-list>
		<template v-slot:append>
			<v-list>
				<v-list-item v-if="!rail">
					<v-expansion-panels>
						<expansion-panel-with-help
							color="success"
							title="Download Options"
							icon="mdi-download">
							<template v-if="dashboard.tab == 'viz'">
								<chart-download-ppt id="mainViz"> </chart-download-ppt>
								<chart-download-png id="mainViz"></chart-download-png>
							</template>
							<download-csv :rows="rows"></download-csv>
						</expansion-panel-with-help>
					</v-expansion-panels>
				</v-list-item>
				<v-list-item title="Collapse Menu" @click="rail = !rail">
					<template v-slot:prepend>
						<v-icon>mdi-menu</v-icon>
					</template>
				</v-list-item>
			</v-list>
		</template>
	</v-navigation-drawer>
</template>

<script setup>
	import {computed, ref, watch} from "vue";
	import {useDashboardStore} from "@/stores/modules/dashboard";
	import {useDataViewsStore} from "@/stores/modules/dataViews";
	import {useAuthStore} from "@/stores/modules/auth";
	import { useOrganisationsStore } from "@/stores/modules/organisations";

	import ExpansionPanelWithHelp from "@/components/ui/ExpansionPanelWithHelp.vue";
	import OrganisationSettings from "@/components/dashboard/OrganisationSettings.vue";
	import AggregateColumn from "@/components/dashboard/AggregateColumn.vue";
	import EditColumns from "@/components/dashboard/EditColumns.vue";
	import ResetButton from "@/components/dashboard/ResetButton.vue";
	import QuickLink from "@/components/dashboard/QuickLink.vue";
	import ChartDownloadPpt from "@c/charts/ChartDownloadPpt";
	import ChartDownloadPng from "@c/charts/ChartDownloadPng";
	import DownloadCsv from "@c/dashboard/DownloadCsv";
	import HandleSelectedRows from "@c/dashboard/HandleSelectedRows";
	import QueryWizardDialog from "@c/data-query-wizard/QueryWizardDialog";

	const rail = ref(false);
	const dashboard = useDashboardStore();
	const auth = useAuthStore();
	const dataViewsStore = useDataViewsStore();
	const dataViews = computed(() => auth.data_views);
	const organisationsStore = useOrganisationsStore();
	watch(
		dataViews,
		(value) => {
			value.forEach((id) => dataViewsStore.fetchById(id));
		},
		{immediate: true}
	);
	// const organisationsStore = useOrganisationsStore();
	const rows = computed(() => dashboard.filteredData);
	const quickLinks = computed(() => {
		let links = [];
		dataViews.value.forEach((view) => {
			if (dataViewsStore.keyed[view]) {
				links.push(dataViewsStore.keyed[view]);
			}
		});
		return links;
	});

	const includedOrganisations = computed(() => dashboard.includedOrganisations );

</script>
