import {useConfigStore} from "./modules/config";

import {useQuestionsStore} from "./modules/questions";
import {useAnswersStore} from "./modules/answers";
import {useSectionsStore} from "./modules/sections";
import {useThemesStore} from "./modules/themes";
import {useServicesStore} from "./modules/services";

const allQuestionsOrdered = () => {
	const config = useConfigStore();
	const sections = useSectionsStore();
	return config.sections.flatMap((sectionId) => {
		const section = sections.keyed[sectionId];
		if (section) {
			return section.questions.map((question) => ({
				question,
				section: sectionId,
				theme: section.theme
			}));
		}
	});
};

const getStore = (str) => {
	let store;
	switch (str) {
		case "question":
			store = useQuestionsStore();
			break;
		case "service":
			store = useServicesStore();
			break;
		case "section":
			store = useSectionsStore();
			break;
		case "theme":
			store = useThemesStore();
			break;
		case "answer":
			store = useAnswersStore();
			break;
		default:
			store = {};
	}
	return store;
};
const getLabels = (str) => {
	const store = getStore(str);
	return {...store.labels, null: "General"};
};


export {allQuestionsOrdered, getLabels, getStore};
