<template>
	<v-sheet>
		<v-card color="#cccccc" v-if="errorState">
			<v-card-text style="text-align: center">
				{{ errorMessage }}
			</v-card-text>
		</v-card>
		<chart-wrapper
			v-else
			:title="title"
			id="mainViz"
			:names="labels"
			defaultGroup="column"
			:datapoints="datapoints"
			:displayedLabel="displayedLabel"
			:displayedSeries="displayedSeries"
			@update="(v) => (dashboard.chartData = v)">
		</chart-wrapper>
	</v-sheet>
</template>

<script setup>
	import _ from "lodash";
	import {computed} from "vue";
	import {getLabels} from "@/stores/getters";
	import {useDashboardStore} from "@/stores/modules/dashboard";
	import ChartWrapper from "@c/charts/ChartWrapper";
	import chartUtils from "@/assets/chartUtils";
	const dashboard = useDashboardStore();

	const tooMuchData = computed(() => {
		return rows.value.length > 30;
	});

	const notQuantifiable = computed(() => {
		return displayedSeries.value.find((s) => !s.is_score) ? true : false;
	});
	const errorState = computed(() => {
		return tooMuchData.value || notQuantifiable.value;
	});

	const errorMessage = computed(() => {
		if (tooMuchData.value) {
			return "Please narrow your selection in order to visualise your data";
		}
		if (notQuantifiable.value) {
			return "You can only visualise numerical data (e.g., scores). Answers are text-based data and cannot be charted.";
		}
		return null;
	});
	let title = "Scottish Government/COSLA Health and Social Care Digital Maturity Self-Assessment 2023";

	const rows = computed(() => dashboard.filteredData);
	const datapoints = computed(() => {
		const series = displayedSeries.value.map((s) => s.value);
		const value = displayedLabel.value;
		return chartUtils.makeDatapoints({rows: rows.value, series, value});
	});

	const displayedLabel = computed(() => {
		let last = _.last(dashboard.displayedLabels);
		return last.value || null;
	});
	const displayedSeries = computed(() => dashboard.displayedSeries);

	const labels = computed(() => {
		let labels = {};
		displayedSeries.value.forEach((series) => {
			labels[series.value] = series.title;
		});
		return {
			...labels,
			...getLabels(displayedLabel.value)
		};
	});
</script>
