<template>
	<v-sheet>
		<h1 class="font-weight-light">Welcome</h1>
		<v-alert color="lightest" class="mt-4 mb-6">
			Welcome to the Scottish Government/COSLA Health and Social Care Digital
			Maturity Self-Assessment data dashboard.
		</v-alert>
		<p class="mb-2">
			Organisations that are participating in the digital maturity exercise can
			access information about their own assessments along with selected
			comparison values here.
		</p>
		<p class="mb-2">
			Data is currently available in table format and in the form of
			visualisations created by users of this tool via the Dashboard page.
		</p>
		<p class="mb-2">
			There are differernt options for downloading information available,
			including csv format for tables, PowerPoint format and image format for
			visualisations.
		</p>
		<p class="mb-2">
			Users of the assessment platform can use their existing accounts to log
			into this site via the login form accessible via the “Login” button at the
			top left of the screen. If you have any questions or experience problems
			with logging in, please contact support. For more information on how to
			use this tool, please visit the tutorial page after logging in.
		</p>
		<p class="mb-2">
			Please note that this website is intended for intended for commissioning
			stakeholders only and is not accessible to the public. If you have any
			general questions or comments, please contact hscdigitalmaturity@gov.scot,
			we will be happy to answer your queries or put you in touch with a member
			of staff who can assist you.
		</p>
	</v-sheet>
</template>

<script setup></script>
