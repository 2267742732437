// TODO - Vuefire doesn't have queries well documented, especially with pagination. Using firebase example and manually updating the store for now.
// https://firebase.google.com/docs/firestore/query-data/query-cursors#web-version-9_3

import _ from "lodash";
import {computed, ref} from "vue";
import {
	getCountFromServer,
	startAfter,
	addDoc,
	query,
	limit,
	orderBy,
	getDocs,
	where,
	getDoc,
	deleteDoc,
	updateDoc,
	setDoc
} from "firebase/firestore";
import {getDocRef, getCollectionRef} from "../../../mwFirebase";
import {useCollection} from "vuefire";
import {useAuthStore} from "@/stores/modules/auth";

const handleSnapshots = (snapshots, store) => {
	if (snapshots.empty || snapshots.docs.length < store.options.limit) {
		store.isFull = true;
	} else {
		store.isFull = false;
	}
	snapshots.forEach((doc) => {
		// console.log( doc.id )
		let obj = {id: doc.id, ...doc.data()};
		let existingIds = store.collection.map((a) => a.id);
		let exclude = store.exclude || [];
		if (!exclude.includes(obj.id) && !existingIds.includes(obj.id)) {
			store.collection.push(obj);
		}
		if (store.onDocumentAdded) {
			store.onDocumentAdded(obj);
		}
	});
	return snapshots;
};

const handleDoc = (doc, store) => {
	if (!doc.exists) {
		return;
	}
	let obj = {id: doc.id, ...doc.data()};
	if( store.keyed[doc.id] ){
		return;
	} 
	store.collection.push(obj);
	if (store.onDocumentAdded) {
		store.onDocumentAdded(obj);
	}
};

export default async ({store}) => {
	if (store.options.type == "collection") {
		if (!store.options.collectionName) {
			store.status = ref("error");
			store.error = ref("Define collection name");
			return;
		}

		store.ref = computed(() => {
			return getCollectionRef(store.options.collectionName);
		});

		store.collection = store.collection || ref([]);

		store.query = computed(() => {
			let q = query(store.ref);
			if (store.options.paginate) {
				if (store.options.sortOrder) {
					q = query(q, orderBy(store.options.orderBy, store.options.sortOrder));
				} else {
					q = query(q, orderBy(store.options.orderBy || "id"));
				}
			}
			if (store.where) {
				store.where.forEach(([key, operator, value]) => {
					q = query(q, where(key, operator, value));
				});
			}
			return q;
		});

		let documentSnapshots = null;

		if (store.options.loadAll) {
			store.collection = useCollection(store.query);
		}

		store.fetch = async () => {
			let q = store.query;
			let lastVisible = documentSnapshots
				? _.last(documentSnapshots.docs)
				: null;
			q = query(q, limit(store.options.limit || 10));
			if (lastVisible) {
				q = query(q, startAfter(lastVisible));
			}
			documentSnapshots = await getDocs(q);
			if (documentSnapshots.empty) {
				return false;
			}
			documentSnapshots = handleSnapshots(documentSnapshots, store);
			// console.log("b: fetching some", store.options.collectionName);
			return true;
		};

		store.fetchAll = async () => {
			store.clearQueries();
			store.collection = useCollection(store.ref);
			// console.log("a: fetching all", store.options.collectionName);
		};

		store.fetchBy = async (key, operator, value) => {
			let snapshots = await getDocs(
				query(store.ref, where(key, operator, value))
			);
			return handleSnapshots(snapshots, store);
		};

		store.fetchById = async (id) => {
			if (!store.keyed[id]) {
				let snapshot = await getDoc(
					getDocRef(store.options.collectionName, id)
					);
					// console.log("d: fetching one", store.options.collectionName);
					handleDoc(snapshot, store);
			}
		};

		store.clearQueries = () => {
			store.collection = [];
			store.isFull = false;
			documentSnapshots = null;
		};

		store.countDocs = async () => {
			const snapshot = await getCountFromServer(store.query);
			store.count = snapshot.data().count;
			return snapshot.data().count;
		};

		store.getRows = async ({page, rowsPerPage}, reset) => {
			if (reset) {
				store.clearQueries();
			}

			store.$patch({options: {limit: rowsPerPage}});
			let totalItems = await store.countDocs();

			while (
				!store.isFull &&
				_.chunk(store.collection, rowsPerPage).length <= page
			) {
				let results = await store.fetch();
				if (!results) {
					break;
				}
			}

			let pages = _.chunk(store.collection, rowsPerPage);
			let items = pages[page - 1] || [];

			return {
				totalItems,
				items
			};
		};

		store.sorted = computed(() => {
			return (by) => {
				return _.sortBy(store.collection, by);
			};
		});

		store.keyed = computed(() => {
			return _.keyBy(store.collection, "id");
		});
		store.byKey = computed(() => {
			return (id) => {
				return store.keyed[id];
			};
		});

		store.create = async (doc) => {
			const auth = useAuthStore();
			doc.created_at = new Date();
			doc.created_by = auth.id;
			if (store.defaultValues) {
				for (var key in store.defaultValues) {
					doc[key] = doc[key] || store.defaultValues[key];
				}
			}
			let newRef = await addDoc(store.ref, doc);
			return newRef.id;
		};

		store.createWithId = async (id, doc) => {
			const auth = useAuthStore();
			doc.created_at = new Date();
			doc.created_by = auth.id;
			if (store.defaultValues) {
				for (var key in store.defaultValues) {
					doc[key] = doc[key] || store.defaultValues[key];
				}
			}
			await setDoc(getDocRef(store.options.collectionName, id), doc);
			return id;
		};

		store.update = async (id, doc) => {
			const auth = useAuthStore();
			doc.updated_at = new Date();
			doc.updated_by = auth.id;
			return await updateDoc(getDocRef(store.options.collectionName, id), doc);
		};

		if (process.env.NODE_ENV === "development") {
			store._customProperties.add("collection").add("keyed").add("isFull");
		}
		store.deleteForever = async (id) => {
			// console.log("deleting", id);
			return await deleteDoc(getDocRef(store.options.collectionName, id));
		};

		store.setWhere = (key, operator, value) => {
			let where = store.where || [];
			where = where.filter((w) => w[0] !== key);
			if (false == value || value && value.length) {
				where.push([key, operator, value]);
			}
			store.$patch({where});
		};

		store.filterBy = computed(() => {
			return (key, value) => {
				return store.collection.filter((a) => a[key] == value);
			};
		});

		store.labels = computed(() => {
			return Object.fromEntries(
				store.collection.map((row) => {
					let value;
					if( typeof store.label == 'function' ){
						value = store.label(row)
					}
					else{
						value = row[store.label || 'name']
					}
					return [row.id, value]
				})
			);
		});

		store.getBy = computed(() => {
			return (key,value) => {
				return store.collection.filter( row => row[key] == value );
			}
		})
		store.getLabel = (k) => {
			if (["null", "undefined"].includes(k) ){
				return "";
			}
			const labels = store.labels;
			return labels[k] ? labels[k] : k;
		};

	}
};
 