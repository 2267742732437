import {httpsCallable} from "firebase/functions";
import {functions} from "../../../mwFirebase.js";
import {defineStore} from "pinia";
import {useDashboardStore} from "./dashboard.js";
import _ from "lodash";

export const useOrganisationsStore = defineStore("organisations", () => {
	// settings for the collection plugin

	const oneOrganisationsDataMany = httpsCallable(
		functions,
		"oneOrganisationsDataMany"
	);
	const fetchData = async (id) => {
		if (id) {
			let results = await oneOrganisationsDataMany({id});
			return JSON.parse(results.data);
		}
	};

	const dashboard = useDashboardStore();
	dashboard;
	const includeOrganisation = async (data) => {
		if( !data ){
			return;
		}
		const results = await fetchData(data.id);
		let all = Object.keys( results ).map( id => ({id, ...results[id]}) )
		all.forEach( row => row.created_at = new Date(row.created_at));
		all = _.sortBy(all, "created_at");
		all = all.reverse();
		data.datalatest = all[0].data;
		dashboard.addSeries( `${data.id}-latest-score`, `${data.name} - Latest Score`, true, true  );
		dashboard.addSeries( `${data.id}-latest-answer`, `${data.name} - Latest Answer`, false, false  );
	};

	return {
		options: {
			type: "collection",
			collectionName: "organisations",
			loadAll: false
		},
		fetchData,
		includeOrganisation
	};
});
