<template>
	<v-card class="quicklink" flat color="white" @click="go">
		{{ title }}
	</v-card>
</template>

<style lang="less">
	.quicklink {
		cursor: pointer;
		padding: 6px;
		margin-top: 8px;
		font-size: 0.8em !important;
		&:hover {
			background-color: #eeeeee !important;
		}
	}
</style>

<script setup>
	import {useOrganisationsStore} from "@/stores/modules/organisations";
	import {useDashboardStore} from "@/stores/modules/dashboard";
	import {defineProps} from "vue";

	const props = defineProps({
		title: String,
		columns: Array,
		organisations: Array,
		filters: Object,
		introduction: String
	});

	const dashboardStore = useDashboardStore();
	const organisationsStore = useOrganisationsStore();
	const go = () => {
		let cols = props.columns;
		props.organisations.forEach((id) => {
			const organisation = organisationsStore.keyed[id];
			cols.push(`${id}-latest-score`)
			organisationsStore.includeOrganisation(organisation);
		});
		props.organisations.forEach
		dashboardStore.setColumns(cols);
		dashboardStore.setFilters(props.filters);
		dashboardStore.setIntro(props.introduction || "");
	};
</script>
