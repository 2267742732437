<template>
	<v-app>
		<v-main>
			<v-sheet>
				<side-navigation></side-navigation>
				<v-sheet class="pa-8">
					<v-app-bar elevation="0">
						<v-app-bar-title>Digital Maturity Assessment</v-app-bar-title>
						<v-spacer></v-spacer>
						<v-img src="./assets/logo.png"></v-img>
					</v-app-bar>
					<router-view />
				</v-sheet>
			</v-sheet>
		</v-main>
		<toast-notice></toast-notice>
	</v-app>
</template>

<script setup>
	import SideNavigation from "@c/navigation/SideNavigation";
	import {computed, watch, onMounted} from "vue";
	import {useAuthStore} from "./stores/modules/auth";
	import ToastNotice from "@c/ui/ToastNotice";
	import {useDashboardStore} from "./stores/modules/dashboard";
	const dashboard = useDashboardStore();
	const auth = useAuthStore();
	auth.login();

	let accessDenied = computed(() => {
		return auth.accessDenied;
	});
	let loggedin = computed(() => auth.isLoggedIn);

	watch(loggedin, async () => {
		await dashboard.fetchAllOrgs();
	});

	watch(
		accessDenied,
		(value) => {
			if (value == true) {
				auth.logout("error");
			}
		},
		{deep: true}
	);

	onMounted( () => {
		window.onload = () => {
			document.title = "SG Data Outputs"
		}
	})
</script>
