import _ from "lodash";
import {httpsCallable} from "firebase/functions";
import {functions} from "../../../mwFirebase.js";
import {allQuestionsOrdered} from "@/stores/getters";
import {defineStore} from "pinia";
import {computed, ref, watch} from "vue";
import {useConfigStore} from "@/stores/modules/config";
import {useQuestionsStore} from "@/stores/modules/questions";
import {useThemesStore} from "./themes";
import {useServicesStore} from "./services";
import {useOrganisationsStore} from "./organisations.js";
import {useSectionsStore} from "./sections.js";
import {useAuthStore} from "./auth.js";
import {useAnswersStore} from "./answers.js";
import {useHistoricalDataStore} from "./historicalData.js";
export const useDashboardStore = defineStore("dashboard", () => {
	const organisationsStore = useOrganisationsStore();
	const historicalDataStore = useHistoricalDataStore();
	const sectionsStore = useSectionsStore();
	const answersStore = useAnswersStore();
	const auth = useAuthStore();

	const loading = ref(false);
	const tab = ref("table");
	const defaultTitle = "Digital Maturity Assessment";
	const title = ref(defaultTitle);
	const introduction = ref("");
	const data = ref([]);
	const chartData = ref(null);
	let national_average = ref([]);
	const selection = ref([]);
	const selected = ref([]);
	const services = ref([]);
	const includedOrganisations = ref([]);
	const columns = ref([
		{value: "theme", key: "theme", title: "Theme", display: false},
		{value: "section", key: "section", title: "Section", display: true},
		{value: "question", key: "question", title: "Question", display: false},
		{value: "service", key: "service", title: "Service", display: false},
		{
			value: "national_average",
			key: "national_average",
			title: "National Average",
			display: true,
			is_score: true
		}
	]);
	const aggregateColumns = ref([]);

	const fetchOwnOrgs = async () => {
		if( auth.permissions.universal_stakeholder ){
			await organisationsStore.fetchBy("test_data", "==", false );

		}
		else if (auth.organisations.length) {
			await Promise.all(
				auth.organisations.map(async (organisation) => {
					await organisationsStore.fetchById(organisation);
				})
			);
		}
		return;
	};
	const fetchRegionalOrgs = async () => {
		if (auth.regions.length) {
			await organisationsStore.fetchBy(
				"region",
				"array-contains-any",
				auth.regions
			);
		}
	};

	const fetchAllOrgs = async () => {
		await fetchRegionalOrgs();
		await fetchOwnOrgs();
		// await fetchHistoricalData();
		let v = organisationsStore.collection.flatMap((item) => item.service);
		services.value = _.uniq(v);
		fetchNationalAverages(services.value);
		return services;
	};

	const allDataMappedById = computed(() => {
		let keyed = {};

		national_average.value.forEach((row) => {
			_.set(keyed, `${row.section}-${row.question}.${row.service}`, {
				national_average: row.average == null ? null : Math.ceil(row.average)
			});
		});
		
		organisationsStore.collection.forEach((organisation) => {
			if (organisation.datalatest) {
				organisation.datalatest.forEach((row) => {
					let scoreKey = `${row.section}-${row.question}.${row.service}.${organisation.id}-latest-score`;
					let score = row.average == null ? null : Math.ceil(row.average);
					_.set(keyed, scoreKey, score);
					_.set(
						keyed,
						`${row.section}-${row.question}.${row.service}.${organisation.id}-latest-answer`,
						Object.keys(row.counts)
							.map((answer) => answersStore.getLabel(answer))
							.join(", ")
					);
				});
			}
			if (organisation.have_historical_data) {
				const data2019 = historicalDataStore.getBy(
					"organisation",
					organisation.id
				);
				data2019.forEach((row) => {
					let service = row.service;
					if (!service || !service.length) {
						service = "null";
					}
					_.set(
						keyed,
						`${row.section}-${row.question}.${service}.${organisation.id}-2019-score`,
						row.score
					);
					_.set(
						keyed,
						`${row.section}-${row.question}.${service}.${organisation.id}-2019-answer`,
						row.answer
					);
				});
			}
		});

		aggregateColumns.value.forEach( ({id, members}) => {
			for( var key in keyed ){
				let row = keyed[key];
				for( var service in row ){
					let values = members.map( member => row[service][member] );
					values = values.filter( v => typeof v !== 'undefined' && v !== null && !isNaN(v));
					let avg = _.mean(values);
					if( isNaN(avg) ){
						row[service][id] = null;
					}
					else{
						row[service][id] = avg;
					}
				}
			}
		} )

		return keyed;
	});

	const themes = computed(() => {
		const coll = sectionsStore.collection || [];
		let values = coll.flatMap((item) => item.theme);
		return _.uniq(values);
	});

	watch(services, (value) => {
		const servicesStore = useServicesStore();
		value.forEach((service) => {
			servicesStore.fetchById(service);
		});
	});

	watch(themes, (value) => {
		const themesStore = useThemesStore();
		value.forEach((theme) => {
			themesStore.fetchById(theme);
		});
	});

	const allData = computed(() => {
		const mapped = allDataMappedById.value;
		let items = allQuestionsOrdered();
		items = items.filter((item) => item);
		if (allDataMappedById.value) {
			items = items
				.flatMap((item) => {
					item._id = _.uniqueId();
					let data = mapped[`${item.section}-${item.question}`];
					if (data) {
						return Object.keys(data).flatMap((service) => {
							let row = {...item};
							row.service = service;
							displayedSeries.value.forEach((series) => {
								row[series.value] = data[service][series.value];
							});
							return row;
						});
					} else {
						// return item;
					}
				})
				.filter((item) => item);
		}
		return items;
	});
	const filteredData = computed(() => filterData(allData.value));

	const filters = ref({section: [], theme: [], question: [], service: []});
	const setFilters = (v) => {
		const f = {section: [], theme: [], question: [], service: [], ...v };
		filters.value = f;
	};

	const resetFilters = () => {
		introduction.value = "";
		filters.value = {section: [], theme: [], question: [], service: []};
	};
	const displayedColumns = computed(() =>
		columns.value.filter((column) => column.display)
	);
	const dataLabelOptions = ref(["question", "service", "section", "theme"]);
	const dataSeriesOptions = ref(["national_average"]);

	const labels = computed(() => {
		return columns.value.filter((item) =>
			dataLabelOptions.value.includes(item.value)
		);
	});
	const series = computed(() => {
		return columns.value.filter((item) =>
			dataSeriesOptions.value.includes(item.value)
		);
	});

	const displayedLabels = computed(() => {
		return displayedColumns.value.filter((item) =>
			dataLabelOptions.value.includes(item.value)
		);
	});
	const displayedSeries = computed(() => {
		return displayedColumns.value.filter((item) =>
			dataSeriesOptions.value.includes(item.value)
		);
	});

	const allAssessmentAverages = httpsCallable(
		functions,
		"allAssessmentAverages"
	);

	const fetchNationalAverages = async (v) => {
		loading.value = true;
		let results = await allAssessmentAverages(v);
		national_average.value = results.data;
		loading.value = false;
	};

	const addSeries = (value, title, is_score, display) => {
		columns.value.push({value, title, is_score, display});
		dataSeriesOptions.value.push(value);
	};

	const start = async () => {
		loading.value = true;
		const config = useConfigStore();
		const questionsStore = useQuestionsStore();
		config.watchDoc();
		await questionsStore.fetchAll();
		loading.value = false;
	};

	// await fetchNationalAverages();

	const isDisplayedColumn = (v) => {
		return displayedColumns.value.find((column) => column.value == v)
			? true
			: false;
	};

	const setIntro = (str) => {
		introduction.value = str || "";
	}

	const setColumns = (arr) => {
		columns.value.forEach((column) => {
			if (arr.includes(column.value)) {
				column.display = true;
			} else {
				column.display = false;
			}
		});
	};
	const aggregateData = (arr) => {
		const keys = ["question", "section", "theme"];
		const key = keys.find((value) => isDisplayedColumn(value));
		let values = _.groupBy(arr, key);
		let previousKeys = keys.slice(keys.indexOf(key) + 1);
		let scoredSeries = computed(() =>
			displayedSeries.value.filter((series) => series.is_score)
		);
		return Object.keys(values).map((e) => {
			let item = {};
			item[key] = e;
			scoredSeries.value.forEach((s) => {
				let scores = values[e].map((v) => v[s.value]);
				scores = scores.filter( s => typeof s !== "undefined" );
				scores = scores.filter((s) => !["null", null].includes(s));
				let avg = _.mean(scores);
				item[s.value] = isNaN(avg) ? "-" : Math.ceil(avg);
			});
			previousKeys.forEach((k) => {
				item[k] = values[e][0][k];
			});
			return item;
		});
	};

	const filterData = (data) => {
		// if no columns are selected - return nothing
		if (displayedLabels.value.length == 0) {
			return [];
		}
		for (const key in filters.value) {
			if (filters.value[key].length) {
				data = data.filter(
					(row) =>
						row[key] == filters.value[key] ||
						filters.value[key].includes(row[key])
				);
			}
		}
		// as service is not superceded by anything, first group by service if necessary
		if (isDisplayedColumn("service")) {
			// if service and question are both selected, return all data without aggregation
			if (!isDisplayedColumn("question")) {
				// return a flat array grouped by both service and a secondary key
				const byService = _.groupBy(data, "service");

				data = Object.keys(byService).flatMap((service) => {
					let values = aggregateData(byService[service]);
					values.forEach((item) => {
						item.service = service;
						return item;
					});
					return values;
				});
			}
		} else {
			// return data grouped by key
			data = aggregateData(data);
		}
		return data;
	};


	const createNewCombinedColumn = ({title, members = [] }) => {
		const id = _.uniqueId();
		aggregateColumns.value.push( {id, title, members})	
		addSeries(id, title, true, true )
	}


	return {
		national_average,
		tab,
		title,
		allData,
		addSeries,
		data,
		allDataMappedById,
		filteredData,
		selection,
		selected,
		chartData,
		loading,
		columns,
		labels,
		series,
		displayedColumns,
		dataLabelOptions,
		dataSeriesOptions,
		displayedLabels,
		displayedSeries,
		setColumns,
		start,
		isDisplayedColumn,
		includedOrganisations,
		filters,
		filterData,
		resetFilters,
		setFilters,
		services,
		fetchAllOrgs,
		fetchNationalAverages, 
		introduction, 
		setIntro, 
		createNewCombinedColumn
	};
});
