<template>
	<div>
		<v-checkbox
			v-for="column in columns"
			:key="column.value"
			:label="column.title"
			v-model="column.display"
			density="compact"
			hide-details>
		</v-checkbox>
		<v-dialog width="300" v-model="dialog">
			<v-card
				title="Change View"
				text="Viewing answers requires that questions are selected and that table view is active. Proceed to table view?">
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="cancel">Cancel</v-btn>	
					<v-btn @click="proceed">Proceed</v-btn>	
				</v-card-actions>	
			</v-card>
		
		</v-dialog>
	</div>
</template>
<script setup>
	import {useDashboardStore} from "@/stores/modules/dashboard";
	import {computed, defineProps, watch, ref} from "vue";
	const props = defineProps({type: String});
	const dashboardStore = useDashboardStore();
	const columns = computed(() => dashboardStore[props.type]);
	const tab = computed(() => dashboardStore.tab);
	const dialog = ref(false);

	const questionColumn = computed(() =>
		columns.value.find((item) => item.value == "question")
	);

	watch(
		questionColumn,
		(newValue) => {
			if (tab.value == "viz" && newValue.display == true) {
				dialog.value = true;
			}
		},
		{deep: true}
	);

	const cancel = () => {
		questionColumn.value.display = false;
		dialog.value = false;
	}

	const proceed = () => {
		dashboardStore.tab = "table";
		dialog.value = false;
	}
</script>
