<template>
	<v-dialog v-model="dialog" width="800" eager>
		<template v-slot:activator="{props}">
			<v-btn class="mt-2" block size="small" color="success" v-bind="props">
				<v-icon class="mr-2">mdi-plus-circle</v-icon>
				<span>Create Custom Column</span>
			</v-btn>
		</template>
		<v-card flat>
			<v-card-title>Create Custom Column</v-card-title>
			<v-card-text>
				<p class="mb-2">This column will display an average score of all questions answered by all organisations you select below.</p>
				<v-alert class="mb-2" color="primary" >
					Calculating average question scores provides a different result from average section scores. 
				</v-alert>
				<v-text-field label="Column title" v-model="title" persistent-placeholder placeholder="Give your column a recognisable title"></v-text-field>
				<v-checkbox
					v-model="include"
					multiple
					density="compact"
					v-for="column in availableColumns"
					:key="column.value"
					:value="column.value"
					:label="column.title"
					hide-details></v-checkbox>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="success" @click="go" :disabled="!title || include.length < 2">Submit</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script setup>
	import {useDashboardStore} from "@/stores/modules/dashboard";
	import {computed, ref} from "vue";

	const dashboard = useDashboardStore();
	const include = ref([]);
	const title = ref("");

	let availableColumns = computed( () => dashboard.columns.filter((c) => c.is_score ) );

	const dialog = ref(false);
	const go = ( ) => {
		dashboard.createNewCombinedColumn({title: title.value, members: include.value })
		dialog.value = false;
	}
</script>
