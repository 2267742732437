import {getCurrentUser} from "vuefire";
import {createRouter, createWebHistory} from "vue-router";
import HomeView from "../views/HomeView.vue";
import DashboardView from "../views/DashboardView.vue";
import SupportView from "../views/SupportView.vue";
import PrivacyView from "../views/PrivacyView.vue";
import TutorialView from "../views/TutorialView.vue";
import ContactUsView from "../views/ContactUsView.vue";

const routes = [
	{
		path: "/",
		name: "home",
		component: HomeView
	},
	{
		path: "/dashboard",
		name: "dashboard",
		component: DashboardView,
		meta: {
			require_auth: true
		}
	},
	{
		path: "/contact",
		name: "contact",
		component: ContactUsView,
		meta: {
			require_auth: false
		}
	},
	{
		path: "/support",
		name: "support",
		component: SupportView,
		meta: {
			require_auth: false
		}
	},	
	{
		path: "/privacy",
		name: "privacy",
		component: PrivacyView,
		meta: {
			require_auth: false
		}
	},
	{
		path: "/tutorials",
		name: "tutorials",
		component: TutorialView,
		meta: {
			require_auth: false
		}
	},	

];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

router.beforeEach(async (to) => {
  let redirect;
	const currentUser = await getCurrentUser();
	if (to.meta.require_auth && !currentUser) {
    redirect;
	}
	if (redirect) {
		return {
			path: "/",
			query: {redirect: to.fullPath}
		};
	}
});

export default router;
