import {defineStore} from "pinia";
import {ref, watch} from "vue";
import { useSectionsStore } from "./sections";

export const useConfigStore = defineStore("config", () => {
	const collectionName = "config";
	const type = "doc";

	let id = ref("default");
	let sections = ref([]);

    watch( sections, (value) => {
        const sectionsStore = useSectionsStore();
        value.forEach( id => sectionsStore.fetchById(id) )
    })


	return {
		id,
		options: {
			collectionName,
			type
		},
        sections
	};
});
