import {defineStore} from "pinia";
import {} from "vue";

export const useHistoricalDataStore = defineStore("historicalData", () => {
	// settings for the collection plugin
	return {
		options: {
			type: "collection",
			collectionName: "historicalData",
			loadAll: false, 
            limit: -1
		},
		
	};
});
