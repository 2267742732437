<template>
	<v-btn @click="reset" color="lightest" block class="mt-2">
		<v-icon class="mr-2">mdi-restore</v-icon>
		<span style="font-size: 0.7em"> Reset Query </span>
	</v-btn>
</template>

<script setup>
	import {useDashboardStore} from "@/stores/modules/dashboard";

	const dashboard = useDashboardStore();
	const reset = () => {
		dashboard.setColumns([
			"section",
			"national_average"
		]);
		dashboard.resetFilters();
	};
</script>
